import { ChannelsMetadata } from "@telia-company/tv.web-playback-sdk";
import { TUnfortunatelyAny } from "@telia-company/tv.web-player-shared";
import { TPlaybackSpec } from "@telia-company/tv.web-player-ui";
import { Dispatch, SetStateAction, useEffect } from "react";

import { useRefAppState } from "../reducer/ref.reducer";

export type Panel = PanelItem[];

export type PanelItem = {
  img: string;
  playbackSpec: TPlaybackSpec;
};

export type Props = {
  setChannels: Dispatch<SetStateAction<TUnfortunatelyAny>>;
};

const convertToPanel = (items: ChannelsMetadata[]): Panel =>
  items
    .map(
      (i: TUnfortunatelyAny): PanelItem => ({
        img: i?.icons?.dark || "",
        playbackSpec: i?.playback?.play?.playbackSpec,
      })
    )
    .filter((i: PanelItem) => i.playbackSpec);

export const useChannelsPanel = ({ setChannels }: Props): void => {
  const { metadataService, playerConf, userId } = useRefAppState();

  useEffect(() => {
    if (!playerConf) return;
    if (!userId) return;

    metadataService
      .getChannels()
      .then((res) => {
        setChannels(convertToPanel(res.slice(0, 5)));
      })
      // eslint-disable-next-line no-console
      .catch(console.error);
  }, [metadataService, playerConf, userId, setChannels]);
};
