import { RecordingIdType } from "@telia-company/tv.web-player-shared";
import { useCallback, useEffect, useState } from "react";

import { UiEventTypes } from "../constants";
import { noop } from "../utils";
import {
  useDispatch,
  useMetadataImpl,
  usePlayerState,
  useTranslations,
} from "./context-provider";

export const useRecordings = () => {
  const { getRecordingCbs } = useMetadataImpl();
  const {
    metadata: { npvrInfo },
    playback,
  } = usePlayerState();
  const dispatch = useDispatch();
  const { recordingStartToast, recordingStopToast } = useTranslations();
  const [toggleRecordingClicked, setToggleRecordingClicked] = useState(false);
  const [recordingType, setRecordingType] = useState<null | RecordingIdType>(
    null
  );
  // Toggle (media) recording
  useEffect(() => {
    if (!playback) return noop;
    if (!getRecordingCbs) return noop;
    if (!toggleRecordingClicked) return noop;
    // need series id to do series recordings
    if (
      recordingType === RecordingIdType.Series &&
      !npvrInfo?.media.metadata.seriesId
    )
      return noop;
    // need media id to record media
    if (recordingType === RecordingIdType.Media && !npvrInfo?.media.metadata.id)
      return noop;
    if (recordingType === null) return noop;

    let aborted = false;

    const { addRecording, removeRecording } = getRecordingCbs;

    // if there is a recordedPlaybackSpec, assume we have an ongoing recording
    // TODO: see if we need to remove single recording first, when setting series recording?
    const hasRecording =
      !!npvrInfo?.media.recordedPlaybackSpec ||
      !!npvrInfo?.media.seriesRecording;
    const canRecord =
      !!npvrInfo?.media.isRecordable || !!npvrInfo?.media.isSeriesRecordable;

    const noLongerRecordable = npvrInfo?.media.recordableBefore
      ? Date.now() > npvrInfo?.media.recordableBefore
      : false;

    // TODO: translation & decide if we want a toast message
    if (noLongerRecordable && recordingType === RecordingIdType.Media) {
      /* dispatch({
        type: UiEventTypes.SHOW_TOAST,
        payload: {
          message: "Program is no longer recordable",
        },
      }); */
      return noop;
    }

    const idToRecord =
      recordingType === RecordingIdType.Series
        ? npvrInfo?.media.metadata.seriesId
        : npvrInfo?.media.metadata.id;

    if (!idToRecord) return noop;

    // we can neither add nor remove a recording of the current program
    if (!hasRecording && !canRecord) return noop;

    const doRecording = () => {
      dispatch({
        payload: {},
        type: UiEventTypes.SHOW_RECORDING_SPINNER,
      });
      addRecording({
        id: idToRecord,
        type: recordingType,
      })
        .then(() => {
          if (aborted) return;
          setToggleRecordingClicked(false);
          // to update recording status
          dispatch({
            payload: {},
            type: UiEventTypes.SHOULD_FETCH_METADATA,
          });
          dispatch({
            payload: {
              message: recordingStartToast,
            },
            type: UiEventTypes.SHOW_TOAST,
          });
          dispatch({
            payload: {},
            type: UiEventTypes.HIDE_RECORDING_SPINNER,
          });
        })
        .catch((e) => {
          if (aborted) return;
          setToggleRecordingClicked(false);
          dispatch({
            payload: {
              message: e.message,
            },
            type: UiEventTypes.SHOW_TOAST,
          });
          dispatch({
            payload: {},
            type: UiEventTypes.HIDE_RECORDING_SPINNER,
          });
        });
    };

    const undoRecording = () => {
      dispatch({
        payload: {},
        type: UiEventTypes.SHOW_RECORDING_SPINNER,
      });
      removeRecording({
        id: idToRecord,
        type: recordingType,
      })
        .then(() => {
          if (aborted) return;
          setToggleRecordingClicked(false);
          // to update recording status
          dispatch({
            payload: {},
            type: UiEventTypes.SHOULD_FETCH_METADATA,
          });
          dispatch({
            payload: {
              message: recordingStopToast,
            },
            type: UiEventTypes.SHOW_TOAST,
          });
          dispatch({
            payload: {},
            type: UiEventTypes.HIDE_RECORDING_SPINNER,
          });
        })
        .catch((e) => {
          if (aborted) return;
          setToggleRecordingClicked(false);
          dispatch({
            payload: {
              message: e.message,
            },
            type: UiEventTypes.SHOW_TOAST,
          });
          dispatch({
            payload: {},
            type: UiEventTypes.HIDE_RECORDING_SPINNER,
          });
        });
    };

    if (recordingType === RecordingIdType.Media) {
      // check if we should add or remove a media recording
      if (!npvrInfo.media.recordedPlaybackSpec && npvrInfo.media.isRecordable) {
        doRecording();
      } else if (npvrInfo.media.recordedPlaybackSpec) {
        undoRecording();
      }
    }

    if (recordingType === RecordingIdType.Series) {
      // check if we should add or remove a series recording
      if (
        !npvrInfo.media.seriesRecording &&
        npvrInfo.media.isSeriesRecordable
      ) {
        doRecording();
      } else if (npvrInfo.media.seriesRecording) {
        undoRecording();
      }
    }

    return () => {
      aborted = true;
    };
  }, [
    dispatch,
    getRecordingCbs,
    playback,
    toggleRecordingClicked,
    npvrInfo,
    recordingStartToast,
    recordingStopToast,
    recordingType,
  ]);

  const toggleRecording = useCallback(
    ({ type }: { type: RecordingIdType }) => {
      setToggleRecordingClicked(true);
      setRecordingType(type);
    },
    [setToggleRecordingClicked, setRecordingType]
  );

  const canRecordMedia = () =>
    !!(npvrInfo?.media.isRecordable && !npvrInfo?.media.seriesRecording);
  const canRecordSeries = () =>
    !!(npvrInfo?.media.isSeriesRecordable && !npvrInfo?.media.seriesRecording);

  return {
    canRecordMedia,
    canRecordSeries,
    toggleSeriesRecording: () =>
      toggleRecording({ type: RecordingIdType.Series }),
    toggleSingleRecording: () =>
      toggleRecording({ type: RecordingIdType.Media }),
  };
};
