import {
  PlayerControls,
  TAudioTrack,
  TPlaybackConfig,
  TPlayerFeatures,
  TTextTrack,
  TTranslations,
} from "@telia-company/tv.web-player-shared";
import {
  createContext,
  Dispatch,
  FC,
  ReactNode,
  RefObject,
  useContext,
  useMemo,
} from "react";

import {
  TCustomStyles,
  TDesign,
  TImageProxyImpl,
  TMetadataImplementations,
  TPlayerIcons,
  TUiCallbacks,
} from "../@types/types";
import { TAction, TState } from "../hooks/ui-state-reducer.hook";

export type TClickHandlers = {
  onJumpToLive: () => void;
  onNextContent: () => void;
  onPause: () => void;
  onPlayContent: (playback: TPlaybackConfig) => void;
  onRecordAndWatch: TOnRecordAndWatch;
  onSeek: (seekToSeconds: number) => void;
  onSelectAudioTrack: (lang: TAudioTrack) => void;
  onSelectTextTrack: (lang: TTextTrack) => void;
  onSetTextTrackVisibility: (visible: boolean) => void;
  onStartover: () => void;
  onTogglePlay: () => void;
  onWatchCredits: () => void;
};

export type TContextProviderProps = {
  callbacks: TUiCallbacks;
  children: ReactNode;
  clickHandlers: TClickHandlers;
  controls?: PlayerControls;
  design: TDesign;
  dispatch: Dispatch<TAction>;
  features: TPlayerFeatures;
  fullscreenElementRef: null | RefObject<HTMLDivElement>;
  imageProxyImpl: TImageProxyImpl;
  metadataImpl: TMetadataImplementations;
  publishCustomTrackingEvent: (name: string) => void;
  state: TState;
  translations: TTranslations;
};

export type TOnRecordAndWatch = (
  recordCallback: () => Promise<TPlaybackConfig>
) => Promise<void>;

export const DefaultStyles: TCustomStyles = {
  accentColor: "#ff6319",
  borderRadius: "0.5em",
  brandColor: "#0099ff",
  brandColorDark: "#11517b",
  buttonBorderRadius: "1.8em",
  fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
  gradient: `
  linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.4) 0%,
    rgba(0, 0, 0, 0) 27%,
    rgba(0, 0, 0, 0) 44%,
    rgba(0, 0, 0, 0.8) 100%
  ),
  linear-gradient(
    90deg,
    rgba(0, 0, 0, 0.5) 0%,
    rgba(0, 0, 0, 0) 33%,
    rgba(0, 0, 0, 0) 66%,
    rgba(0, 0, 0, 0.5) 100%
  );`,
  languageOverlayColor: "rgba(26,1,40,0.7)",
  progressBarColor: "#0099ff",
  progressBarColorDark: "#11517b",
  recordingColor: "#e4175c",
  relatedContentListItemBackground: "#1D2734",
};

export const DefaultFeatures: TPlayerFeatures = {
  hideRelatedContent: false,
};

const CustomStyleContext = createContext<TCustomStyles>(DefaultStyles);
export const SmallLayoutContext = createContext<boolean>(false);
const ClickHandlerContext = createContext<TClickHandlers>({} as TClickHandlers);
const StateContext = createContext<TState>({} as TState);
const TranslationContext = createContext<TTranslations>({} as TTranslations);
const CallbackContext = createContext<TUiCallbacks>({} as TUiCallbacks);
const MetadataImplContext = createContext<TMetadataImplementations>(
  {} as TMetadataImplementations
);
const ImageProxyImplContext = createContext<TImageProxyImpl>(
  (() => "") as TImageProxyImpl
);
const DispatchContext = createContext<Dispatch<TAction>>(
  (() => {}) as Dispatch<TAction>
);
const PublishCustomTrackingEvent = createContext<(name: string) => void>(
  (() => {}) as (name: string) => void
);
const FullscreenElementContext =
  createContext<null | RefObject<HTMLDivElement>>(null);
const PlayerControlsContext = createContext<PlayerControls | undefined>(
  {} as PlayerControls
);
const IconContext = createContext<TPlayerIcons>({} as TPlayerIcons);
const FeatureToggleContext = createContext<TPlayerFeatures>(
  DefaultFeatures as TPlayerFeatures
);

export const useCustomStyles = (): TCustomStyles =>
  useContext(CustomStyleContext);
export const useIcon = (): TPlayerIcons => useContext(IconContext);
export const useSmallLayout = (): boolean => useContext(SmallLayoutContext);
export const useClickHandler = (): TClickHandlers =>
  useContext(ClickHandlerContext);
export const usePlayerState = (): TState => useContext(StateContext);
export const useTranslations = (): TTranslations =>
  useContext(TranslationContext);
export const useUiCallbacks = (): TUiCallbacks => useContext(CallbackContext);
export const useDispatch = (): Dispatch<TAction> => useContext(DispatchContext);
export const usePublishCustomTrackingEvent = (): ((name: string) => void) =>
  useContext(PublishCustomTrackingEvent);
export const useFullscreenElement = (): null | RefObject<HTMLDivElement> =>
  useContext(FullscreenElementContext);
export const usePlayerControls = (): PlayerControls | undefined =>
  useContext(PlayerControlsContext);
export const useFeatureToggle = (): TPlayerFeatures =>
  useContext(FeatureToggleContext);
export const useMetadataImpl = (): TMetadataImplementations =>
  useContext(MetadataImplContext);
export const useImageProxy = (): TImageProxyImpl =>
  useContext(ImageProxyImplContext);

export const UiScaleContext = createContext(1);

export const useUiScale = (): number => useContext(UiScaleContext);

export const ContextProviders: FC<TContextProviderProps> = ({
  callbacks,
  children,
  clickHandlers,
  controls,
  design: { customStyles, icons },
  dispatch,
  features,
  fullscreenElementRef,
  imageProxyImpl,
  metadataImpl,
  publishCustomTrackingEvent,
  state,
  translations,
}) => {
  const styleContextValue = useMemo(
    () => ({
      ...DefaultStyles,
      ...customStyles,
    }),
    [customStyles]
  );

  const featureTogglesValue = useMemo(
    () => ({ ...DefaultFeatures, ...features }),
    [features]
  );

  return (
    <CustomStyleContext.Provider value={styleContextValue}>
      <IconContext.Provider value={icons}>
        <ClickHandlerContext.Provider value={clickHandlers}>
          <TranslationContext.Provider value={translations}>
            <CallbackContext.Provider value={callbacks}>
              <MetadataImplContext.Provider value={metadataImpl}>
                <ImageProxyImplContext.Provider value={imageProxyImpl}>
                  <DispatchContext.Provider value={dispatch}>
                    <PublishCustomTrackingEvent.Provider
                      value={publishCustomTrackingEvent}
                    >
                      <FullscreenElementContext.Provider
                        value={fullscreenElementRef}
                      >
                        <StateContext.Provider value={state}>
                          <PlayerControlsContext.Provider value={controls}>
                            <FeatureToggleContext.Provider
                              value={featureTogglesValue}
                            >
                              {children}
                            </FeatureToggleContext.Provider>
                          </PlayerControlsContext.Provider>
                        </StateContext.Provider>
                      </FullscreenElementContext.Provider>
                    </PublishCustomTrackingEvent.Provider>
                  </DispatchContext.Provider>
                </ImageProxyImplContext.Provider>
              </MetadataImplContext.Provider>
            </CallbackContext.Provider>
          </TranslationContext.Provider>
        </ClickHandlerContext.Provider>
      </IconContext.Provider>
    </CustomStyleContext.Provider>
  );
};
