import { AccessControl, TThumbnail } from "@telia-company/tv.web-player-shared";
import { FC, useCallback, useRef, useState } from "react";

import { UiEventTypes } from "../../constants";
import {
  useClickHandler,
  useCustomStyles,
  useDispatch,
  usePlayerState,
  useSmallLayout,
  useTranslations,
  useUiScale,
} from "../context-provider";
import { AdMarkers } from "./ad-marker";
import {
  ProgressBar,
  ProgressDot,
  ProgressTrackContainer,
  SeekBubble,
  Thumbnail,
  ThumbnailWrapper,
} from "./elements";
import { useLastDragPositionWhileSeeking } from "./last-drag-position-while-seeking.hook";
import { ProgressTrack } from "./progress-track";
import { useProgress } from "./progress.hook";
import { calculateTimestamp, SeekableArea } from "./seekable-area";
import { LeftTimer, RightTimer } from "./time-indicator";
import { useTimestamps } from "./timestamps.hook";
import { getProgressDotLabel, getProgressPosition } from "./util";

export type TProgressBarContainer = {
  canJumpToLive: boolean;
  isOngoingStartoverOrLiveEvent: boolean;
};

export const ProgressBarContainer: FC<TProgressBarContainer> = ({
  canJumpToLive,
  isOngoingStartoverOrLiveEvent,
}) => {
  const smallLayout = useSmallLayout();
  const { onJumpToLive } = useClickHandler();
  const {
    adMarkers,
    atLiveEdge,
    currentTime,
    duration: vodDuration,
    isInAdBreak,
    liveSeekEnabled,
    metadata: { endTime, startTime /* programsInSeekRange */ },
    playback,
    playbackType,
    seeking,
    seekRange,
    showProgressBar,
    trickPlayRestrictions: { noFastForward, noRewind },
  } = usePlayerState();
  const dispatch = useDispatch();
  const { progressBarColor, progressBarColorDark } = useCustomStyles();
  const {
    a11yElapsed,
    a11yEndTime,
    a11yRemaining,
    a11yStartTime,
    a11yXofX,
    scrubbingNotAllowed,
  } = useTranslations();

  const [mouseProgressPosition, setMouseProgressPosition] = useState(0);
  const [keyboardScrubbingPosition, setKeyboardScrubbingPosition] = useState<
    null | number
  >(null);
  const [seekLabelTimestamp, setSeekLabelTimestamp] = useState<null | string>(
    null
  );
  const [isDragging, setIsDragging] = useState(false);
  const [dragPosition, setDragPosition] = useState<null | number>(null);
  const [seekRequested, setSeekRequested] = useState(false);
  const [thumbnail, setThumbnail] = useState<{
    img: TThumbnail;
    offset: {
      left: null | number;
      right: null | number;
    } | null;
    pos: number;
  } | null>(null);
  const progressDotRef = useRef<HTMLDivElement>(null);
  const scaleFactor = useUiScale();
  const { onSeek } = useClickHandler();
  const thumbnailWrapperRef = useRef(null);

  const dispatchShowScrubbingInfo = useCallback(
    () =>
      dispatch({
        payload: {
          message: scrubbingNotAllowed,
        },
        type: UiEventTypes.SHOW_TOAST,
      }),
    [dispatch, scrubbingNotAllowed]
  );

  useLastDragPositionWhileSeeking({
    currentTime,
    isDragging,
    seeking,
    seekRequested,
    setDragPosition,
    setSeekRequested,
  });

  const { leftTimer, rightTimer } = useTimestamps({
    currentTime,
    endTime,
    liveSeekEnabled,
    playbackType,
    seekRange,
    startTime,
    vodDuration,
  });

  const { progressPercent, seekWindowPercent } = useProgress({
    currentTime,
    endTime,
    liveSeekEnabled,
    playbackType,
    seekRange,
    startTime,
    vodDuration,
  });

  // "timeupdate" events have not started yet
  if (currentTime === null) return null;
  // progress calculations could not be performed for some reason
  if (progressPercent === null || !leftTimer) return null;

  const progressPosition = getProgressPosition({
    dragPosition,
    progressPercent,
    seekWindowPercent,
  });

  const allowSeek = !(noFastForward && noRewind) && !isInAdBreak;
  const getProgressMax = () => {
    if (typeof seekWindowPercent === "number") {
      return parseInt((seekWindowPercent * 100).toFixed(0), 10);
    }

    return 100;
  };

  const getProgressNow = () => {
    const percent =
      typeof keyboardScrubbingPosition === "number"
        ? keyboardScrubbingPosition * 100
        : progressPercent * 100;
    return Math.max(
      0,
      Math.min(getProgressMax(), parseInt(percent.toFixed(0), 10))
    );
  };

  return (
    <ProgressBar
      aria-keyshortcuts={allowSeek && seekRange ? "← →" : ""}
      aria-label={`${getProgressDotLabel({ atLiveEdge, currentTime, endTime, liveSeekEnabled, seekLabelTimestamp, startTime, translations: { a11yElapsed, a11yEndTime, a11yRemaining, a11yStartTime, a11yXofX }, vodDuration })}`}
      role="group"
      smallLayout={smallLayout}
      visible={showProgressBar}
    >
      {!smallLayout && (
        <LeftTimer
          label={playbackType === "vod" ? a11yElapsed : a11yStartTime}
          smallLayout={smallLayout}
          text={leftTimer}
        />
      )}
      <ProgressTrackContainer
        noTimerRight={rightTimer === null}
        shortTimerLeft={leftTimer.length < 6}
        shortTimerRight={rightTimer === null || rightTimer.length < 6}
        smallLayout={smallLayout}
      >
        {thumbnail && !isInAdBreak && (
          <ThumbnailWrapper
            ref={thumbnailWrapperRef}
            scaleFactor={scaleFactor}
            style={{ left: `${thumbnail.pos * 100}%` }}
          >
            {thumbnail.offset && (
              <Thumbnail img={thumbnail.img} offset={thumbnail.offset} />
            )}
          </ThumbnailWrapper>
        )}
        <ProgressTrack
          atLiveEdge={atLiveEdge}
          canJumpToLive={canJumpToLive}
          isNpvr={playback?.playbackSpec.accessControl === AccessControl.NPVR}
          isOngoingStartoverOrLiveEvent={isOngoingStartoverOrLiveEvent}
          liveSeekEnabled={liveSeekEnabled}
          mouseProgressPosition={mouseProgressPosition}
          onJumpToLive={onJumpToLive}
          playbackType={playbackType}
          progressBarColor={progressBarColor}
          progressBarColorDark={progressBarColorDark}
          progressPosition={progressPosition}
          seekWindowPercent={seekWindowPercent}
          showScrubbingInfo={dispatchShowScrubbingInfo}
        />
        {seekLabelTimestamp && !isInAdBreak && (
          <SeekBubble
            aria-live="assertive"
            style={{
              left: keyboardScrubbingPosition
                ? `${keyboardScrubbingPosition * 100}%`
                : `${mouseProgressPosition * 100}%`,
            }}
          >
            {seekLabelTimestamp}
          </SeekBubble>
        )}
        {allowSeek && (
          <SeekableArea
            isDragging={isDragging}
            keyboardScrubbingPosition={keyboardScrubbingPosition}
            liveSeekEnabled={liveSeekEnabled}
            playbackType={playbackType}
            progressDotRef={progressDotRef}
            progressPercent={progressPercent}
            seekWindowPercent={seekWindowPercent}
            setDragPosition={setDragPosition}
            setIsDragging={setIsDragging}
            setMouseProgressPosition={setMouseProgressPosition}
            setSeekLabelTimestamp={setSeekLabelTimestamp}
            setSeekRequested={setSeekRequested}
            setThumbnail={setThumbnail}
            thumbnailWrapperRef={thumbnailWrapperRef}
          />
        )}
        {adMarkers && vodDuration && <AdMarkers adMarkers={adMarkers} />}
        {allowSeek && seekRange && (
          <ProgressDot
            aria-keyshortcuts="← →"
            aria-live="off"
            aria-valuemax={getProgressMax()}
            aria-valuemin={0}
            aria-valuenow={getProgressNow()}
            aria-valuetext={seekLabelTimestamp || `${getProgressNow()}%`}
            horizontal
            onBlur={() => {
              setKeyboardScrubbingPosition(null);
              setSeekLabelTimestamp(null);
              setThumbnail(null);
              dispatch({
                payload: {},
                type: UiEventTypes.HIDE_UI_MENU,
              });
            }}
            onFocus={() => {
              dispatch({
                payload: {},
                type: UiEventTypes.SHOW_UI_MENU,
              });
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter" || e.key === " ") {
                if (keyboardScrubbingPosition !== null && seekWindowPercent) {
                  setSeekRequested(true);
                  setSeekLabelTimestamp(null);
                  const targetSeekPosition = seekRange
                    ? seekRange.start +
                      (seekRange.end - seekRange.start) *
                        (keyboardScrubbingPosition / seekWindowPercent)
                    : null;
                  if (targetSeekPosition !== null) {
                    onSeek(targetSeekPosition);
                  }
                }
                e.stopPropagation();
                e.preventDefault();
              }
              if (e.key === "ArrowLeft") {
                const newKeyboardScrubbingPosition = Math.min(
                  seekWindowPercent || 1,
                  Math.max(
                    0,
                    keyboardScrubbingPosition !== null
                      ? (keyboardScrubbingPosition * 100 - 1) / 100
                      : (progressPosition - 1) / 100
                  )
                );
                setKeyboardScrubbingPosition(newKeyboardScrubbingPosition);
                setSeekLabelTimestamp(
                  calculateTimestamp({
                    endTime,
                    liveSeekEnabled,
                    percent: newKeyboardScrubbingPosition,
                    playbackType,
                    seekRange,
                    startTime,
                  })
                );
                e.preventDefault();
                e.stopPropagation();
              }
              if (e.key === "ArrowRight") {
                const newKeyboardScrubbingPosition = Math.min(
                  seekWindowPercent || 1,
                  Math.max(
                    0,
                    keyboardScrubbingPosition !== null
                      ? (keyboardScrubbingPosition * 100 + 1) / 100
                      : (progressPosition + 1) / 100
                  )
                );
                setKeyboardScrubbingPosition(newKeyboardScrubbingPosition);
                setSeekLabelTimestamp(
                  calculateTimestamp({
                    endTime,
                    liveSeekEnabled,
                    percent: newKeyboardScrubbingPosition,
                    playbackType,
                    seekRange,
                    startTime,
                  })
                );
                e.preventDefault();
                e.stopPropagation();
              }
            }}
            ref={progressDotRef}
            role="slider"
            style={{
              left:
                keyboardScrubbingPosition !== null
                  ? `${(keyboardScrubbingPosition * 100).toFixed(1)}%`
                  : `${progressPosition.toFixed(1)}%`,
            }}
            tabIndex={0}
          />
        )}
      </ProgressTrackContainer>
      {!!rightTimer && (
        <RightTimer
          label={playbackType === "vod" ? a11yRemaining : a11yEndTime}
          smallLayout={smallLayout}
          text={rightTimer}
        />
      )}
    </ProgressBar>
  );
};
