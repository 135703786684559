export const absolutePositionFullSizeBlock = `
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
`;

export const visuallyHidden = `
  position: absolute; 
  overflow: hidden; 
  clip: rect(0 0 0 0); 
  height: 1px; width: 1px; 
  margin: -1px; padding: 0; border: 0; 
`;

export const centeredFlexIcon = `
  display: flex;
  align-items: center;
  justify-content: center;
  
  svg {
    width: 100%;
    height: auto;
  }
`;

export const brandRed = "#FF3334";

const fadeTransition = `transition: opacity 0.3s, visibility 0.3s;`;

export const fadeAnimation = (visible: boolean): string => `
  opacity: ${visible ? "1" : "0"};
  ${fadeTransition}
`;

export const animatedHidden = `
  opacity: 0;
  ${fadeTransition}
`;

export const animatedVisible = `
  opacity: 1;
`;
