import { createContext, Dispatch, useContext, useReducer } from "react";

import {
  TContentItemMetadata,
  TFillerContentItemMetadata,
} from "../types/types";
import { clampToFullPageRenderingOffset, listPadding } from "../util";

export const fillerItem: TFillerContentItemMetadata = {
  id: "filler",
  image: "",
  subtitle: null,
  title: "filler",
  type: "filler",
};

export type TPanelData = {
  items: Array<TContentItemMetadata>;
};

export type TRenderTick = {
  payload: number;
  type: "RENDER_TICK";
};

export const renderTickAction = (now: number): TRenderTick => ({
  payload: now,
  type: "RENDER_TICK",
});

export type TNewPanelData = {
  payload: TPanelData;
  type: "NEW_PANEL_DATA";
};

export const newPanelDataAction = (payload: TPanelData): TNewPanelData => ({
  payload,
  type: "NEW_PANEL_DATA",
});

export type TNextPage = {
  type: "NEXT_PAGE";
};

export type TPreviousPage = {
  type: "PREVIOUS_PAGE";
};

export const nextPageAction = (): TNextPage => ({
  type: "NEXT_PAGE",
});

export const previousPageAction = (): TPreviousPage => ({
  type: "PREVIOUS_PAGE",
});

export type TIndexStatePayload = {
  indexOfCurrentItem: number;
  pageSize: number;
  totalItemsInList: number;
};

export type TUpdateIndexState = {
  payload: TIndexStatePayload;
  type: "INDEX_STATE_UPDATE";
};

export const updateIndexState = (
  payload: TIndexStatePayload
): TUpdateIndexState => ({
  payload,
  type: "INDEX_STATE_UPDATE",
});

export type TAction =
  | TNewPanelData
  | TNextPage
  | TPreviousPage
  | TRenderTick
  | TUpdateIndexState;

export type TPanelState = {
  dataFetchOffset: null | number;
  indexOfCurrentItem: null | number;

  isLeftArrowVisible: boolean;
  isRightArrowVisible: boolean;
  loadingNewPage: boolean;

  pageSize: null | number;

  panelItems: TPanelData;
  refreshListData: boolean;

  renderingOffset: null | number;
  totalItemsInList: null | number;
};

const initialState: TPanelState = {
  dataFetchOffset: null,
  indexOfCurrentItem: null,

  isLeftArrowVisible: false,
  isRightArrowVisible: false,
  loadingNewPage: true,

  pageSize: null,

  panelItems: { items: [fillerItem] },
  refreshListData: false,

  renderingOffset: null,
  totalItemsInList: null,
};

const checkIfProgramEnded = (item: TContentItemMetadata, time: number) => {
  if (item.type === "sport") {
    return item.endTime < time;
  }

  if (item.type === "channel" || item.type === "npvr") {
    return item.currentProgram.endTime && item.currentProgram.endTime < time;
  }

  return false;
};

const reducer = (state: TPanelState, action: TAction): TPanelState => {
  switch (action.type) {
    // Reset the list if indices, totalItems, season (etc.) changes.
    case "INDEX_STATE_UPDATE":
      return {
        ...initialState,
        ...action.payload,
        dataFetchOffset: Math.max(
          clampToFullPageRenderingOffset({
            pageSize: action.payload.pageSize,
            renderingOffset: action.payload.indexOfCurrentItem,
            // TODO update naming here. Kill old mini epg.
            totalChannelsInEngagement: action.payload.totalItemsInList,
          }) - 1,
          0
        ),
        refreshListData: true,
        renderingOffset: clampToFullPageRenderingOffset({
          pageSize: action.payload.pageSize,
          renderingOffset: action.payload.indexOfCurrentItem,
          totalChannelsInEngagement: action.payload.totalItemsInList,
        }),
      };
    case "NEW_PANEL_DATA": {
      if (
        typeof state.totalItemsInList !== "number" ||
        typeof state.renderingOffset !== "number" ||
        typeof state.pageSize !== "number"
      )
        return state;

      const isRightArrowVisible =
        state.totalItemsInList > state.renderingOffset + state.pageSize;
      const isLeftArrowVisible = state.renderingOffset > 0;

      return {
        ...state,
        isLeftArrowVisible,
        isRightArrowVisible,
        loadingNewPage: false,

        panelItems: {
          ...action.payload,
          items:
            state.renderingOffset === 0
              ? // Add a filler item when on first page to make rendering logic simpler
                [fillerItem, ...action.payload.items].slice(
                  0,
                  state.pageSize + listPadding
                )
              : action.payload.items,
        },
        refreshListData: false,
      };
    }
    case "NEXT_PAGE": {
      if (
        typeof state.renderingOffset !== "number" ||
        typeof state.pageSize !== "number" ||
        typeof state.totalItemsInList !== "number"
      )
        return state;

      const renderingOffset = clampToFullPageRenderingOffset({
        pageSize: state.pageSize,
        renderingOffset: state.renderingOffset + state.pageSize,
        totalChannelsInEngagement: state.totalItemsInList,
      });
      const dataFetchOffset = Math.max(renderingOffset - listPadding / 2, 0);

      return {
        ...state,
        dataFetchOffset,
        isLeftArrowVisible: renderingOffset >= listPadding / 2,
        isRightArrowVisible:
          state.totalItemsInList > renderingOffset + state.pageSize,
        loadingNewPage: true,
        refreshListData: true,
        renderingOffset,
      };
    }
    case "PREVIOUS_PAGE": {
      if (
        typeof state.renderingOffset !== "number" ||
        typeof state.pageSize !== "number" ||
        typeof state.totalItemsInList !== "number"
      )
        return state;

      const renderingOffset = Math.max(
        state.renderingOffset - state.pageSize,
        0
      );
      const dataFetchOffset = Math.max(renderingOffset - listPadding / 2, 0);

      return {
        ...state,
        dataFetchOffset,
        isLeftArrowVisible: renderingOffset > 0,
        isRightArrowVisible:
          state.totalItemsInList > renderingOffset + state.pageSize,
        loadingNewPage: true,
        refreshListData: true,
        renderingOffset,
      };
    }
    // Triggers re-render of progress bars due to returning new state object
    case "RENDER_TICK": {
      // Find out type of panel items
      const type = state.panelItems?.items?.find(
        (i) => i.type !== "filler"
      )?.type;

      // No need to re-render series view
      if (type === "series" || !type) return state;

      if (type === "channel" || type === "npvr") {
        // Trigger re-render for epg and check if it should be refreshed
        return {
          ...state,
          // Only toggle if not in progress of fetching new page
          refreshListData: state.loadingNewPage
            ? state.refreshListData
            : !!state.panelItems?.items.find((item) =>
                checkIfProgramEnded(item, action.payload)
              ),
        };
      }

      if (type === "sport") {
        // trigger re-render, no need to re-fetch data
        return {
          ...state,
        };
      }

      // Always refresh for other types of content or empty view
      return {
        ...state,
        // Only toggle if not in progress of fetching new page
        refreshListData: state.loadingNewPage ? state.refreshListData : true,
      };
    }
    default:
      return state;
  }
};

export const usePanelStateReducer = (): [TPanelState, Dispatch<TAction>] =>
  useReducer(reducer, initialState);

export const PanelStateContext = createContext<TPanelState>(initialState);
export const usePanelState = (): TPanelState => useContext(PanelStateContext);

export const PanelStateDispatchContext = createContext<Dispatch<TAction>>(
  {} as Dispatch<TAction>
);
export const usePanelDispatch = (): Dispatch<TAction> =>
  useContext(PanelStateDispatchContext);
