import { TUnfortunatelyAny } from "@telia-company/tv.web-player-shared";
import { TPlaybackSpec, TPlayerConfig } from "@telia-company/tv.web-player-ui";
import { Dispatch, SetStateAction, useEffect } from "react";

import { /* cw, */ cwmk2, media /* ml */ } from "../misc/queries";
import { useRefAppState } from "../reducer/ref.reducer";

export type Panel = PanelItem[];

export type PanelItem = {
  img: string;
  playbackSpec: TPlaybackSpec;
  title: string;
};

// const posterRatio = 3 / 2;
// const posterWidth = 200;

export type Props = {
  setCwItems: Dispatch<SetStateAction<TUnfortunatelyAny>>;
  setMlItems: Dispatch<SetStateAction<TUnfortunatelyAny>>;
};

const convertToPanelMk2 = (items: TUnfortunatelyAny): Panel =>
  items
    .map(
      (i: TUnfortunatelyAny): PanelItem => ({
        img: i?.images?.showcard2x3?.sourceNonEncoded || "",
        playbackSpec:
          i?.playback?.play?.subscription?.[0]?.item?.playbackSpec ||
          i?.playback?.play?.rental?.[0]?.item?.playbackSpec ||
          i?.playback?.play?.linear?.[0]?.item?.playbackSpec,
        title: i?.title,
      })
    )
    .filter((i: PanelItem) => i.playbackSpec);

const getRequestOptions = (
  playerConf: TPlayerConfig,
  query: string,
  variables: TUnfortunatelyAny
) => ({
  body: JSON.stringify({
    // operationName: "cw",
    query,
    variables: {
      ...variables,
    },
  }),
  headers: {
    "client-name": "web",
    "client-version": "0.0.0",
    "tv-client-boot-id": playerConf.applicationSessionId,
    "X-Country": playerConf.serviceCountry.toUpperCase(),
  },
  ignoreGlobalHeaders: false,
  useAuthentication: true,
});

const getPanelXRequestOptions = (
  playerConf: TPlayerConfig,
  query: string,
  type: string
) => ({
  body: JSON.stringify({
    // operationName: "cw",
    query,
    variables: {
      limit: 5,
      panelXId:
        type === "cw"
          ? "known-panel|container-continue-watching"
          : "known-panel|container-my-list",
    },
  }),
  headers: {
    "client-name": "web",
    "client-version": "0.0.0",
    "tv-client-boot-id": playerConf.applicationSessionId,
    "X-Country": playerConf.serviceCountry.toUpperCase(),
  },
  ignoreGlobalHeaders: false,
  useAuthentication: true,
});

export const useContentPanels = ({ setCwItems, setMlItems }: Props): void => {
  const { playerConf, requestFactory, userId } = useRefAppState();

  useEffect(() => {
    if (!playerConf) return;
    if (!userId) return;

    Promise.all([
      requestFactory.fetch(
        `${playerConf.graphUrl}`,
        getPanelXRequestOptions(playerConf, cwmk2, "cw")
      ),
      requestFactory.fetch(
        `${playerConf.graphUrl}`,
        getPanelXRequestOptions(playerConf, cwmk2, "mylist")
      ),
    ])
      .then(([cwResult /* mlResult */]) => {
        const cwIds = cwResult.responseBody?.data?.panelX?.posters.items
          .slice(0, 5)
          .map((cw: TUnfortunatelyAny) => cw.link.link3.to);
        // const myListIds =
        //   mlResult.responseBody.data?.panelX?.posters.items.slice(0, 5).map((ml: TUnfortunatelyAny) => ml.link.link3.to);

        Promise.all(
          cwIds.map((id: string) => {
            return requestFactory
              .fetch(
                `${playerConf.graphUrl}`,
                getRequestOptions(playerConf, media, {
                  mediaId: id,
                })
              )
              .then((res) => {
                return res.responseBody?.data?.media;
              });
          })
        ).then((cwItems) => {
          if (cwItems) {
            setCwItems(convertToPanelMk2(cwItems.slice(0, 5)));
          }
        });
      })
      // eslint-disable-next-line no-console
      .catch(console.error);
  }, [playerConf, requestFactory, setCwItems, setMlItems, userId]);
};
