import {
  ErrorCategories,
  PlaybackEventTypes,
} from "@telia-company/tv.web-playback-sdk";

import {
  TCallback,
  TEngineCallbacks,
  TEventHandler,
  TEventMethods,
  TUnfortunatelyAny,
} from "../shared-types";

export type THlsStallEventHandlerOptions = {
  callbacks: TEngineCallbacks;
  event: Omit<TEventMethods, "all" | "clear" | "publish">;
  player: TUnfortunatelyAny;
  videoElement: HTMLVideoElement;
};

export type THlsStallEventHandlerPair = [
  PlaybackEventTypes,
  (options: THlsStallEventHandlerOptions) => TCallback,
];

type TGetHandler = (options: THlsStallEventHandlerOptions) => TCallback;

type THandlers = {
  onLoaded: TCallback | undefined;
};

/**
 * HLS streams will sometimes stall in the beginning, buffering endlessly.
 * This is an attempt to detect cases where the buffer is filled, but currentTime
 * is outside the buffer. We will then gently nudge currentTime so that it is
 * inside the buffer, and this will hopefully cause video playback to start.
 *
 */
export const getHlsStallEventHandler = (
  options: THlsStallEventHandlerOptions
): TEventHandler => {
  let hlsStallDetectionInterval: number | undefined;
  let errorTimeout: TUnfortunatelyAny | undefined;
  let listenersAdded = false;

  const getBufferUpperLimit = () => {
    const {
      streaming: { rebufferingGoal },
    } = options.player.getConfiguration();

    if (typeof rebufferingGoal === "number" && !Number.isNaN(rebufferingGoal)) {
      return Math.max(4, rebufferingGoal);
    }

    return 12; // default if we don't have a rebufferingGoal configured
  };

  const initialHandlers: THandlers = {
    onLoaded: undefined,
  };

  let handlers: THandlers = {
    ...initialHandlers,
  };

  const getOnLoaded: TGetHandler = () => {
    if (handlers.onLoaded) return handlers.onLoaded;

    handlers.onLoaded = (): void => {
      removeHlsStallEventListeners();
    };

    return handlers.onLoaded;
  };

  const hlsStallListenersArray: Array<THlsStallEventHandlerPair> = [
    [PlaybackEventTypes.LOADED, getOnLoaded],
  ];

  const removeHlsStallEventListeners = (): void => {
    if (hlsStallDetectionInterval) clearInterval(hlsStallDetectionInterval);
    if (errorTimeout) clearTimeout(errorTimeout);

    hlsStallListenersArray.forEach(([event, getHandler]) =>
      options.event.off(event, getHandler(options))
    );

    handlers = {
      ...initialHandlers,
    };

    hlsStallDetectionInterval = undefined;
    listenersAdded = false;
  };

  const publishError = () => {
    options.callbacks.onError({
      error: {
        category: ErrorCategories.MEDIA,
        code: "HLS_PLAYLIST_STALLED",
        details: {},
        fatal: true,
        message:
          "Tried to recover from stall when currentTime outside buffered range",
      },
    });
  };

  const addHlsStallEventListeners = (): void => {
    if (listenersAdded)
      throw new Error("Hls stall event listeners already bound");

    const { videoElement } = options;

    hlsStallListenersArray.forEach(([event, getHandler]) =>
      options.event.on(event, getHandler(options))
    );

    const maxCurrentTimeToBufferStartDiff = 6;
    const bufferUpperLimit = getBufferUpperLimit();

    if (!hlsStallDetectionInterval) {
      hlsStallDetectionInterval = window.setInterval(() => {
        if (
          videoElement.buffered.length &&
          typeof videoElement.currentTime === "number"
        ) {
          const currentTimeInsideBuffered =
            videoElement.currentTime >= videoElement.buffered.start(0) &&
            videoElement.currentTime < videoElement.buffered.end(0);

          const bufferSize =
            videoElement.buffered.end(0) - videoElement.buffered.start(0);

          const currentTimeCloseEnough =
            Math.abs(
              videoElement.buffered.start(0) - videoElement.currentTime
            ) < maxCurrentTimeToBufferStartDiff;

          // currentTime is outside buffer, less than X seconds from buffer.start,
          // and we have more than rebufferingGoal seconds buffered. Kick currentTime.
          if (
            !currentTimeInsideBuffered &&
            bufferSize > bufferUpperLimit &&
            currentTimeCloseEnough
          ) {
            videoElement.currentTime = videoElement.buffered.start(0) + 1;

            errorTimeout = setTimeout(publishError, 4000);

            removeHlsStallEventListeners();
          }
        }
      }, 1000);
    }
    listenersAdded = true;
  };

  return {
    addEventListeners: addHlsStallEventListeners,
    removeEventListeners: removeHlsStallEventListeners,
  };
};
