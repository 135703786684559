import styled from "@emotion/styled";
import { FC } from "react";
import { useHotkeys } from "react-hotkeys-hook";

import { TStartoverButtonProps } from "../@types/types";
import { Hotkeys, HotkeyTooltips } from "../hooks/global-hotkeys.hook";
import { GridIcon } from "./elements";

const StartoverButtonWrapper = styled.div<{ position: number }>`
  grid-column: ${({ position }) => `${position} / ${position + 3}`};
  grid-row: 9;
  grid-template-columns: 1fr 2fr;
  display: grid;
`;

const StartoverIcon = styled(GridIcon)`
  grid-column: 1;
`;

const ButtonLabel = styled.span`
  overflow: hidden;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 0.9em;
  line-height: 1;
  text-overflow: ellipsis;
  white-space: nowrap;
  grid-column: 2;
`;

export const StartoverButton: FC<TStartoverButtonProps> = ({
  clickHandler,
  Icon,
  position,
  text,
  tooltip,
}) => {
  useHotkeys(Hotkeys.TOGGLE_STARTOVER, clickHandler, {}, [clickHandler]);
  return (
    <StartoverButtonWrapper
      aria-keyshortcuts={HotkeyTooltips.TOGGLE_STARTOVER}
      aria-label={`${tooltip} (${HotkeyTooltips.TOGGLE_STARTOVER.toUpperCase()})`}
      data-microtip-position="top"
      data-tooltip
      onClick={clickHandler}
      onKeyDown={(e) => {
        if (e.key === " " || e.key === "Enter") {
          clickHandler();
          e.stopPropagation();
          e.preventDefault();
        }
      }}
      onMouseLeave={(e) => e.currentTarget.blur()}
      position={position}
      role="button"
      tabIndex={0}
    >
      <StartoverIcon tabIndex={-1}>
        <Icon />
      </StartoverIcon>
      <ButtonLabel>{text}</ButtonLabel>
    </StartoverButtonWrapper>
  );
};
