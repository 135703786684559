import { IQuery, TimeRange } from "../Types";
import { Images } from "./Generics";

export type ChannelQueryArgs = {
  id: string;
  timeRange?: TimeRange;
  limit?: number;
};

export class ChannelMetadataQuery implements IQuery {
  public query?: string;

  generateBody(queryVariables: ChannelQueryArgs): string {
    let start = null;
    let limit = queryVariables.limit || 2;
    //let duration = null;
    if (queryVariables.timeRange) {
      if (queryVariables.timeRange.start instanceof Date) {
        queryVariables.timeRange.start =
          queryVariables.timeRange.start.valueOf();
      }
      if (queryVariables.timeRange.end instanceof Date) {
        queryVariables.timeRange.end = queryVariables.timeRange.end.valueOf();
      }

      start = queryVariables.timeRange.start;
      limit = 144; // We usually fetch 12 hours worth of programmes, for kids channels 20 is too low
    }
    const operationName = "channel";
    this.query = `query channel($id: String!) {
  channel(id: $id) {
    id
    name
    icons2 {
      compact {
        dark {
          sourceNonEncoded
        }
        light {
          sourceNonEncoded
        }
      }
      large {
        dark {
          sourceNonEncoded
        }
        light {
          sourceNonEncoded
        }
      }
    }
    playback {
      play {
        playbackSpec {
          accessControl
          videoId
          videoIdType
          watchMode
        }
      }
    }
      programs(limit: ${limit >= 1 ? limit : 1}${
      start ? `, timestamp: ${start}` : ""
    }) {
      programItems {
        id
        startTime {
          timestamp
        }
        endTime {
          timestamp
        }
        live
        rerun
        media {
          __typename
          ... on Movie {
            id
            description
            images {
              ${Images}
            }
            title
            playback {
              play {
                linear {
                  item {
                    startover {
                      playbackSpec {
                        videoId
                        videoIdType
                        watchMode
                        accessControl
                      }
                    }
                  }
                }
                npvr {
                  npvrInfo {
                    series {
                      active
                    }
                  }
                  item {
                    playbackSpec {
                      videoId
                      videoIdType
                      watchMode
                      accessControl
                    }
                    validFrom {
                      timestamp
                    }
                    validTo {
                      timestamp
                    }
                  }
                }
              }
              buy {
                npvr {
                  recordableBefore {
                    timestamp
                  }
                  item {
                    playbackSpec {
                      videoId
                      videoIdType
                      watchMode
                      accessControl
                    }
                    validFrom {
                      timestamp
                    }
                    validTo {
                      timestamp
                    }
                  }
                }
              }
            }
          }
          ... on Episode {
            id
            description
            seasonNumber {
              number
            }
            episodeNumber {
              number
            }
            series {
              id
              title
              isRecordable
              userData {
                npvrInfo {
                  active
                }
              }
            }
            images {
              ${Images}
            }
            title
            playback {
              play {
                linear {
                  item {
                    startover {
                      playbackSpec {
                        videoId
                        videoIdType
                        watchMode
                        accessControl
                      }
                    }
                  }
                }
                npvr {
                  npvrInfo {
                    series {
                      active
                    }
                  }
                  item {
                    playbackSpec {
                      videoId
                      videoIdType
                      watchMode
                      accessControl
                    }
                    validFrom {
                      timestamp
                    }
                    validTo {
                      timestamp
                    }
                  }
                }
              }
              buy {
                npvr {
                  recordableBefore {
                    timestamp
                  }
                  item {
                    playbackSpec {
                      videoId
                      videoIdType
                      watchMode
                      accessControl
                    }
                    validFrom {
                      timestamp
                    }
                    validTo {
                      timestamp
                    }
                  }
                }
              }
            }
          }
          ... on SportEvent {
            id
            description
            images {
              showcard16x9 {
                sourceNonEncoded
              }
              showcard2x3 {
                sourceNonEncoded
              }
            }
            title
            playback {
              play {
                linear {
                  item {
                    startover {
                      playbackSpec {
                        videoId
                        videoIdType
                        watchMode
                        accessControl
                      }
                    }
                  }
                }
                npvr {
                  item {
                    playbackSpec {
                      videoId
                      videoIdType
                      watchMode
                      accessControl
                    }
                  }
                  npvrInfo {
                    expirationDate {
                      readableDistance
                    }
                    originalAirDate {
                      startDate {
                        isoString
                      }
                      endDate {
                        timestamp
                        isoString
                      }
                    }
                    series {
                      active
                    }
                  }
                }
              }
              buy {
                npvr {
                  recordableBefore {
                    timestamp
                  }
                  item {
                    playbackSpec {
                      videoId
                      videoIdType
                      watchMode
                      accessControl
                    }
                    validFrom {
                      timestamp
                    }
                    validTo {
                      timestamp
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
`;
    return JSON.stringify({
      operationName,
      query: this.query,
      variables: queryVariables,
    });
  }
}

export class ChannelsMetadataQuery implements IQuery {
  public query?: string;
  generateBody(): string {
    let start = Date.now();
    let limit = 3;

    const operationName = "channels";
    this.query = `query channels {
  channels {
    channelItems {
      id
      name
      icons2 {
        compact {
          dark {
            sourceNonEncoded
          }
          light {
            sourceNonEncoded
          }
        }
        large {
          dark {
            sourceNonEncoded
          }
          light {
            sourceNonEncoded
          }
        }
      }
      playback {
        play {
          playbackSpec {
            videoId
            videoIdType
            accessControl
            watchMode
          }
        }
      }
      programs(limit: 1) {
        programItems {
          startTime {
            timestamp
          }
          endTime {
            timestamp
          }
          media {
            __typename
            ... on Movie {
              id
              description
              images {
                ${Images}
              }
              title
              playback {
                play {
                  linear {
                    item {
                      startover {
                        playbackSpec {
                          videoId
                          videoIdType
                          watchMode
                          accessControl
                        }
                      }
                    }
                  }
                  npvr {
                    npvrInfo {
                      series {
                        active
                      }
                    }
                    item {
                      playbackSpec {
                        videoId
                        videoIdType
                        watchMode
                        accessControl
                      }
                      validFrom {
                        timestamp
                      }
                      validTo {
                        timestamp
                      }
                    }
                  }
                }
                buy {
                  npvr {
                    recordableBefore {
                      timestamp
                    }
                    item {
                      playbackSpec {
                        videoId
                        videoIdType
                        watchMode
                        accessControl
                      }
                      validFrom {
                        timestamp
                      }
                      validTo {
                        timestamp
                      }
                    }
                  }
                }
              }
            }
            ... on Episode {
              id
              description
              seasonNumber {
                number
              }
              episodeNumber {
                number
              }
              series {
                id
                title
                isRecordable
                userData {
                  npvrInfo {
                    active
                  }
                }
              }
              images {
                ${Images}
              }
              title
              playback {
                play {
                  linear {
                    item {
                      startover {
                        playbackSpec {
                          videoId
                          videoIdType
                          watchMode
                          accessControl
                        }
                      }
                    }
                  }
                  npvr {
                    npvrInfo {
                      series {
                        active
                      }
                    }
                    item {
                      playbackSpec {
                        videoId
                        videoIdType
                        watchMode
                        accessControl
                      }
                      validFrom {
                        timestamp
                      }
                      validTo {
                        timestamp
                      }
                    }
                  }
                }
                buy {
                  npvr {
                    recordableBefore {
                      timestamp
                    }
                    item {
                      playbackSpec {
                        videoId
                        videoIdType
                        watchMode
                        accessControl
                      }
                      validFrom {
                        timestamp
                      }
                      validTo {
                        timestamp
                      }
                    }
                  }
                }
              }
            }
            ... on SportEvent {
              id
              description
              images {
                showcard16x9 {
                  sourceNonEncoded
                }
                showcard2x3 {
                  sourceNonEncoded
                }
              }
              title
              playback {
                play {
                  linear {
                    item {
                      startover {
                        playbackSpec {
                          videoId
                          videoIdType
                          watchMode
                          accessControl
                        }
                      }
                    }
                  }
                  npvr {
                    item {
                      playbackSpec {
                        videoId
                        videoIdType
                        watchMode
                        accessControl
                      }
                    }
                    npvrInfo {
                      expirationDate {
                        readableDistance
                      }
                      originalAirDate {
                        startDate {
                          isoString
                        }
                        endDate {
                          timestamp
                          isoString
                        }
                      }
                      series {
                        active
                      }
                    }
                  }
                }
                buy {
                  npvr {
                    recordableBefore {
                      timestamp
                    }
                    item {
                      playbackSpec {
                        videoId
                        videoIdType
                        watchMode
                        accessControl
                      }
                      validFrom {
                        timestamp
                      }
                      validTo {
                        timestamp
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
   `;
    return JSON.stringify({
      operationName,
      query: this.query,
    });
  }
}
