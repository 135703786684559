import {
  Id3Cue,
  ID3Parser,
  StreamCueTypes,
} from "@telia-company/tv.web-playback-sdk";

import {
  TCallback,
  TEngineCallbacks,
  TEventHandler,
  TUnfortunatelyAny,
} from "../shared-types";

export type DataCue = {
  data?: TUnfortunatelyAny;
  value?: { data: ArrayBuffer; key: string };
} & TextTrackCue;

interface DataCueList extends TextTrackCueList {
  [index: number]: DataCue;
  getCueById(id: string): DataCue | null;
}

interface DataTrack extends TextTrack {
  activeCues: DataCueList;
}

const createId3Cue = (startTime: number, tags: Id3Cue["tags"]): Id3Cue => ({
  startTime,
  tags,
});

const createId3Tag = ({ data, key }: { data: ArrayBuffer; key: string }) => ({
  data: new Uint8Array(data),
  key,
});

export const cueChangeEventHandler = (
  { target }: Event,
  callbacks: TEngineCallbacks
): void => {
  if (target && (<TextTrack>target).activeCues) {
    const cues: DataCue[] = Array.from((<DataTrack>target).activeCues);

    if (!(cues && cues.length)) return;

    // Required to fulfil TS contract
    const dummyData = new Uint8Array();

    if (cues[0]?.data instanceof ArrayBuffer) {
      // Browser DID NOT parse ID3 for us.
      // This is untested, as of writing this code Native
      // playback is only used in Safari

      cues.forEach((c) => {
        callbacks.onStreamCue({
          parsed: ID3Parser.Parse(new Uint8Array(c.data), c.startTime),
          raw: dummyData,
          type: StreamCueTypes.ID3,
        });
      });
    } else {
      // Browser DID parse ID3 for us.

      cues
        .reduce((acc, curr): Id3Cue[] => {
          if (!curr.value) return acc;

          // Start by creating an id3 cue array
          if (!acc.length) {
            return [createId3Cue(curr.startTime, [createId3Tag(curr.value)])];
          }

          // Collect tags that share startTime
          if (acc[acc.length - 1].startTime === curr.startTime) {
            acc[acc.length - 1].tags.push(createId3Tag(curr.value));

            return acc;
          }

          // Add new cue for each new startTime
          acc.push(createId3Cue(curr.startTime, [createId3Tag(curr.value)]));

          return acc;
        }, [] as Id3Cue[])
        .forEach((id3) => {
          callbacks.onStreamCue({
            parsed: id3,
            raw: dummyData,
            type: StreamCueTypes.ID3,
          });
        });
    }
  }
};

export type TAddTrackEventHandlerArray = Array<TAddTrackEventHandlerPair>;

export type TAddTrackEventHandlerOptions = {
  callbacks: TEngineCallbacks;
  videoElement: HTMLVideoElement;
};

export type TAddTrackEventHandlerPair = [
  string,
  (options: TAddTrackEventHandlerOptions) => TCallback,
];

export type TGetHandler = (options: TAddTrackEventHandlerOptions) => TCallback;

type THandlers = {
  onAddTrack: TCallback | undefined;
};

type TInitialHandlers = {
  onAddTrack: undefined;
};

export const getNativeCueMetadataHandler = (
  options: TAddTrackEventHandlerOptions
): TEventHandler => {
  let listenersAdded = false;

  const initialHandlers: TInitialHandlers = {
    onAddTrack: undefined,
  };

  let handlers: THandlers = {
    ...initialHandlers,
  };

  const getOnAddTrack: TGetHandler = () => {
    if (handlers.onAddTrack) return handlers.onAddTrack;

    handlers.onAddTrack = ({ track }: TrackEvent): void => {
      if (track?.kind === "metadata") {
        track.mode = "hidden";
        track.addEventListener("cuechange", (evt: Event) => {
          cueChangeEventHandler(evt, options.callbacks);
        });
      }
    };

    return handlers.onAddTrack;
  };

  const listenerArray: TAddTrackEventHandlerArray = [
    ["addtrack", getOnAddTrack],
  ];

  const addEventListeners = (): void => {
    if (listenersAdded) throw new Error("Cue event listeners already bound");

    listenerArray.forEach(([event, getHandler]) =>
      options.videoElement.textTracks.addEventListener(
        event,
        getHandler(options)
      )
    );

    listenersAdded = true;
  };

  const removeEventListeners = (): void => {
    listenerArray.forEach(([event, getHandler]) =>
      options.videoElement.textTracks.removeEventListener(
        event,
        getHandler(options)
      )
    );

    handlers = {
      ...initialHandlers,
    };

    listenersAdded = false;
  };

  return {
    addEventListeners,
    removeEventListeners,
  };
};
