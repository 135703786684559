import styled from "@emotion/styled";
import { TCallback } from "@telia-company/tv.web-player-shared";
import React, {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";

import { TPlayerIcon } from "../../../../@types/icon-type";
import { ButtonWithIcon } from "../../../buttons/button-with-icon";
import { useIcon, useTranslations } from "../../../context-provider";
import { TRelatedContentSeason } from "../types/types";
import { findFirstPoster } from "../util";

const margin = "5.5%";

const SeasonSelectorList = styled.ul<{
  leftArrowVisible: boolean;
  rightArrowVisible: boolean;
}>`
  list-style: none;
  padding: 0.25em;
  line-height: 1;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 0.25em;
  display: flex;
  grid-gap: 0.25em;
  font-weight: bold;
  overflow-x: scroll;
  scrollbar-width: none;
  width: 100%;
  &::-webkit-scrollbar {
    display: none;
  }
  ${({ leftArrowVisible, rightArrowVisible }) => {
    const both = leftArrowVisible && rightArrowVisible;
    if (both)
      return `mask-image: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(0,0,0,1) 10%, rgba(0,0,0,1) 90%, rgba(255,255,255,0) 100%);`;
    if (leftArrowVisible)
      return `mask-image: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(0,0,0,1) 10%);`;
    if (rightArrowVisible)
      return `mask-image: linear-gradient(90deg, rgba(0,0,0,1) 90%, rgba(255,255,255,0) 100%);`;
    return "";
  }}
`;

const SeasonSelectorListItem = styled.li<{ selected: boolean }>`
  padding: 0.75em 1em;
  border-radius: 0.25em;
  cursor: pointer;
  font-size: 0.8em;
  white-space: nowrap;

  ${({ selected }) =>
    selected
      ? `background: rgba(255,255,255,0.15);
    color: #fff;
    cursor: default;`
      : ""}

  &:hover {
    ${({ selected }) =>
      !selected ? `background: rgba(255,255,255,0.15);` : ""};
  }
`;

const ArrowIconContainer = styled.div`
  width: 1.5em;
  height: 55%;
  position: relative;
  display: flex;
  align-items: center;
  padding-left: 0.25em;
  padding-right: 0.25em;
  &:hover {
    background: rgba(255, 255, 255, 0.15);
  }
`;

const ButtonGridWrapper = styled.div`
  grid-column: 16;
  padding-left: 1em;
`;

const SeriesRelatedContentHeaderWrapper = styled.div`
  width: 100%;
  display: grid;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5em;
  padding: 0 ${margin};
`;

const TitleText = styled.div`
  margin: 0 ${margin};
  align-self: start;
  font-size: 1em;
`;

enum Direction {
  LEFT,
  RIGHT,
}

export type TSeriesHeaderProps = {
  buttonIcon: TPlayerIcon;
  buttonText: string;
  headerText: string;
  onClose: TCallback;
  seasons: TRelatedContentSeason[];
  selectedSeason: null | number;
  setSelectedSeason: Dispatch<SetStateAction<null | number>>;
} & React.JSX.IntrinsicAttributes;

export const SeriesRelatedContentHeader: FC<TSeriesHeaderProps> = ({
  buttonIcon,
  buttonText,
  headerText,
  onClose,
  seasons,
  selectedSeason,
  setSelectedSeason,
}) => {
  const translations = useTranslations();
  const { IconNavigateLeft, IconNavigateRight } = useIcon();
  const [leftArrowVisible, setLeftArrowVisible] = useState(false);
  const [rightArrowVisible, setRightArrowVisible] = useState(false);

  const seasonListRef = useRef<HTMLUListElement>(null);

  const checkArrowVisibility = () => {
    if (!seasonListRef.current || seasonListRef.current === null) return;
    const { clientWidth, scrollLeft, scrollWidth } = seasonListRef.current;
    setLeftArrowVisible(scrollLeft > 0);
    setRightArrowVisible(scrollWidth - clientWidth > scrollLeft);
  };

  const scrollSeasons = (dir: Direction) => {
    if (seasonListRef === null || seasonListRef.current === null) return;
    // should scroll to somewhere between 0 and seasonRef.scrollWidth
    const step = Math.round(seasonListRef.current.clientWidth / 3);
    const amount = dir === Direction.RIGHT ? step : step * -1;
    seasonListRef.current.scrollLeft = Math.min(
      Math.max(seasonListRef.current.scrollLeft + amount, 0),
      seasonListRef.current.scrollWidth
    );
    checkArrowVisibility();
  };

  useEffect(() => {
    if (seasonListRef.current) {
      const selected = Array.from(seasonListRef.current.children).filter(
        (i) => i.getAttribute("data-selected") === "true"
      )[0] as HTMLLIElement;
      if (selected) {
        selected.focus();
      } else {
        seasonListRef.current?.focus();
      }
    }
  }, []);

  useEffect(() => {
    if (!seasonListRef.current) return;
    checkArrowVisibility();
  }, [seasonListRef]);

  return (
    <div aria-label={headerText} role="group">
      <TitleText>{headerText}</TitleText>
      <SeriesRelatedContentHeaderWrapper>
        {leftArrowVisible ? (
          <ArrowIconContainer
            aria-hidden="true"
            onClick={() => scrollSeasons(Direction.LEFT)}
            role="presentation"
          >
            <IconNavigateLeft />
          </ArrowIconContainer>
        ) : null}
        <SeasonSelectorList
          leftArrowVisible={leftArrowVisible}
          onFocus={(e) => {
            // when navigating from the episode list to the season list, restore focus
            if (
              e.relatedTarget?.parentElement !== e.target.parentElement &&
              seasonListRef.current
            ) {
              const selected = Array.from(
                seasonListRef.current.children
              ).filter(
                (i) => i.getAttribute("data-selected") === "true"
              )[0] as HTMLLIElement;
              if (selected) {
                selected.focus();
              } else {
                seasonListRef.current?.focus();
              }
            }
          }}
          onKeyDown={(e) => {
            if (e.key === "ArrowUp") {
              e.preventDefault();
              e.stopPropagation();
            }
            if (e.key === "ArrowDown") {
              e.preventDefault();
              e.stopPropagation();
              try {
                const firstPoster = findFirstPoster(e.currentTarget);
                (firstPoster as HTMLDivElement)?.focus();
              } catch (_err) {
                const secondaryFocusTarget = e.currentTarget?.parentElement
                  ?.parentElement?.parentElement?.lastElementChild
                  ?.firstElementChild?.firstElementChild
                  ?.nextElementSibling as HTMLDivElement;
                if (secondaryFocusTarget) {
                  secondaryFocusTarget.focus();
                }
              }
            }
            if (e.key === "Enter" || e.key === " ") {
              e.preventDefault();
              e.stopPropagation();
            }
          }}
          ref={seasonListRef}
          rightArrowVisible={rightArrowVisible}
          role="menu"
          tabIndex={-1}
        >
          {seasons.map(({ season }) => (
            <SeasonSelectorListItem
              data-selected={season === selectedSeason}
              key={season}
              onBlur={checkArrowVisibility}
              onClick={(ev) => {
                // TODO: a bit buggy when clicking near the end of the season list
                // and the "Season"-text expands
                ev.currentTarget.scrollIntoView({
                  behavior: "smooth",
                  block: "nearest",
                  inline: "center",
                });
                setSelectedSeason(season);
                setTimeout(() => {
                  checkArrowVisibility();
                }, 0);
              }}
              onFocus={(e) => {
                e.currentTarget.scrollIntoView({
                  behavior: "smooth",
                  block: "nearest",
                  inline: "center",
                });
                setTimeout(() => {
                  checkArrowVisibility();
                }, 0);
              }}
              onKeyDown={(e) => {
                if (e.key === "ArrowLeft") {
                  e.preventDefault();
                  e.stopPropagation();
                  const prev = e.currentTarget
                    .previousElementSibling as HTMLUListElement;
                  if (prev) {
                    prev.focus();
                  }
                }
                if (e.key === "ArrowRight") {
                  e.preventDefault();
                  e.stopPropagation();
                  const next = e.currentTarget
                    .nextElementSibling as HTMLUListElement;
                  if (next) {
                    next.focus();
                  }
                }
                if (e.key === "Enter" || e.key === " ") {
                  e.preventDefault();
                  e.stopPropagation();
                  e.currentTarget.scrollIntoView({
                    behavior: "smooth",
                    block: "nearest",
                    inline: "center",
                  });
                  setSelectedSeason(season);
                }
                setTimeout(() => {
                  checkArrowVisibility();
                }, 0);
              }}
              role="menuitem"
              selected={season === selectedSeason}
              tabIndex={0}
            >
              {season === selectedSeason ? translations.season : ""} {season}
            </SeasonSelectorListItem>
          ))}
        </SeasonSelectorList>
        {rightArrowVisible ? (
          <ArrowIconContainer
            aria-hidden="true"
            onClick={() => scrollSeasons(Direction.RIGHT)}
            role="presentation"
          >
            <IconNavigateRight />
          </ArrowIconContainer>
        ) : null}
        <ButtonGridWrapper
          onKeyDown={(e) => {
            if (e.key === "ArrowLeft") {
              // go back to season list?
              e.stopPropagation();
              e.preventDefault();
            }
            if (e.key === "ArrowUp" || e.key === "ArrowDown") {
              e.preventDefault();
              e.stopPropagation();
            }
          }}
          role="none"
        >
          <ButtonWithIcon
            Icon={buttonIcon}
            onClick={onClose}
            text={buttonText}
          />
        </ButtonGridWrapper>
      </SeriesRelatedContentHeaderWrapper>
    </div>
  );
};
