import {
  PlaybackEventTypes,
  TPlaybackEvent,
  TYospaceConfiguration,
  YospacePlaybackFeature,
} from "@telia-company/tv.web-playback-sdk";
import {
  AdSession,
  canSeek,
  MainPlayer,
  TEventMethods,
  TPlayerEvent,
  WebPlayerEventType,
} from "@telia-company/tv.web-player-shared";

export type TGetYospaceAdSessionOptions = {
  event: TEventMethods;
  yospaceConfiguration: TYospaceConfiguration;
};

export const getYospaceAdSession = async ({
  event,
  yospaceConfiguration,
}: TGetYospaceAdSessionOptions): Promise<AdSession> => {
  const yospace = new YospacePlaybackFeature(yospaceConfiguration);
  let videoElement: HTMLVideoElement | undefined;
  let engine: MainPlayer | undefined;

  const handler = (
    _type: PlaybackEventTypes | WebPlayerEventType,
    evt: TPlaybackEvent | TPlayerEvent
  ) => {
    switch (evt.type) {
      case PlaybackEventTypes.ADVERTISEMENT_BREAK_STARTED: {
        if (!engine) return;

        const currentSpeed = engine.getSpeed();

        // Cancel FFWD when entering an ad break
        if (currentSpeed > 1) {
          engine.setSpeed(1);

          event.publish({
            payload: {
              newSpeed: 1,
              previousSpeed: currentSpeed,
            },
            type: WebPlayerEventType.SPEED_CHANGE,
          });
        }
        break;
      }
      case PlaybackEventTypes.BUFFERED:
        yospace.buffered();
        break;
      case PlaybackEventTypes.BUFFERING:
        yospace.buffering();
        break;
      case PlaybackEventTypes.LOADED:
        yospace.loaded();
        break;
      case PlaybackEventTypes.PAUSED:
        yospace.paused();
        break;
      case PlaybackEventTypes.PLAYING:
        yospace.playing();
        break;
      case PlaybackEventTypes.STOPPED:
        yospace.stopped();

        //  AdSession currently unsubscribes itself and ends
        //  the yospace session. Maybe make .destroy() part of
        //  the adSession API and destroy it externally?
        event.off("*", handler);

        yospace.destroy();
        break;
      case PlaybackEventTypes.STREAM_CUE:
        if (evt.payload.parsed) {
          yospace.id3Cue(evt.payload.parsed);
        }
        break;
      case WebPlayerEventType.VIDEO_ELEMENT_READY:
        videoElement = evt.payload.videoElement;
        break;
      default:
        break;
    }
  };

  event.on("*", handler);

  const streamUrl = await yospace.initialize();

  return {
    createControls: (e, restrictions) => {
      engine = e;

      return {
        getDuration: () => yospace.contentDuration,
        getPosition: () => {
          if (!videoElement) return undefined;

          return yospace.getPositionWithoutAds(videoElement.currentTime);
        },
        getSpeed: e.getSpeed,
        getThumbnail: e.getThumbnail,
        pause: () => {
          if (restrictions.noPause) return;

          e.pause();
        },
        play: e.play,
        seek: (positionWithoutAds) => {
          const currentPosition = e.getPosition();

          if (typeof currentPosition !== "number") return;

          if (
            canSeek({
              currentPositionInSeconds: currentPosition,
              destination: positionWithoutAds,
              restrictions,
            })
          ) {
            if (positionWithoutAds === "LIVE") {
              e.seek(positionWithoutAds);
            } else {
              const seekPositionData =
                yospace.getSeekPosition(positionWithoutAds);

              e.seek(seekPositionData.seekPosition);

              if (seekPositionData.seekPositionAfterBreakEnd) {
                const delayedSeekHandler = () => {
                  if (seekPositionData.seekPositionAfterBreakEnd) {
                    e.seek(seekPositionData.seekPositionAfterBreakEnd);
                  }

                  event.off(
                    PlaybackEventTypes.ADVERTISEMENT_BREAK_ENDED,
                    delayedSeekHandler
                  );
                };

                event.on(
                  PlaybackEventTypes.ADVERTISEMENT_BREAK_ENDED,
                  delayedSeekHandler
                );
              }
            }
          }
        },
        setAudioTrack: e.setAudioTrack,
        setSpeed: (speed) => {
          const currentSpeed = e.getSpeed();

          if (yospace.inAdBreak) {
            if (currentSpeed > 1) {
              e.setSpeed(1);

              event.publish({
                payload: {
                  newSpeed: 1,
                  previousSpeed: currentSpeed,
                },
                type: WebPlayerEventType.SPEED_CHANGE,
              });
            }
          } else {
            e.setSpeed(speed);

            event.publish({
              payload: {
                newSpeed: speed,
                previousSpeed: currentSpeed,
              },
              type: WebPlayerEventType.SPEED_CHANGE,
            });
          }
        },
        setSubtitleTrack: e.setSubtitleTrack,
        setSubtitleVisibility: e.setSubtitleVisibility,
      };
    },
    getAdBreaks: () => yospace.advertisementBreaks,
    getCurrentAdvertisementClickThroughUrl: () =>
      yospace.getCurrentAdvertisementClickThroughUrl(),
    getCurrentAdvertisementDuration: () =>
      yospace.getCurrentAdvertisementDuration(),
    getCurrentAdvertisementTime: () => yospace.getCurrentAdvertisementTime(),
    getDurationWithoutAds: () => yospace.contentDuration,
    getPositionWithAds: (positionWithoutAds) =>
      yospace.getPositionWithAds(positionWithoutAds),
    getPositionWithoutAds: (positionWithAds) =>
      yospace.getPositionWithoutAds(positionWithAds),
    getStreamUrl: () => streamUrl,
    isPlayingAd: () => yospace.inAdBreak,
    timeUpdate: (currentTime) => {
      // don't try to do any ad skipping on live streams, will lead to a seek loop
      if (yospaceConfiguration.live) {
        yospace.timeUpdate(currentTime);
        return;
      }
      const timeIsInWatchedBreak = yospace.advertisementBreaks.find((b) => {
        if (b.watched) {
          return b.isPositionInAdvertisementBreak(currentTime);
        }

        return false;
      });

      if (engine && timeIsInWatchedBreak) {
        const isRewinding = engine.getSpeed() < 0;

        if (isRewinding) {
          // seek to before adbreak
          engine.seek(yospace.getPositionWithoutAds(currentTime) - 1);
        } else {
          // seek past ad break
          const proposedSeekPosition = Math.ceil(
            yospace.getPositionWithAds(
              yospace.getPositionWithoutAds(currentTime)
            )
          );

          engine.seek(proposedSeekPosition);
        }
      }

      yospace.timeUpdate(currentTime);
    },
  };
};
