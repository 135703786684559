import { PlaybackEventTypes } from "@telia-company/tv.web-playback-sdk";

import { WebPlayerEventType } from "../shared-constants";
import { TCallback, TEventMethods } from "../shared-types";

export type THandlers = {
  onPause: TCallback | undefined;
  onPlaying: TCallback | undefined;
};

export type TInitialHandlers = {
  onPause: undefined;
  onPlaying: undefined;
};

export type TPauseEventHandlerArray = Array<TPauseEventHandlerPair>;

export type TPauseEventHandlerOptions = {
  event: TEventMethods;
};

export type TPauseEventHandlerPair = [
  PlaybackEventTypes,
  (options: TPauseEventHandlerOptions) => TCallback,
];

export type TTimerUtility = {
  start: TCallback;
  stop: TCallback;
};

type TGetHandler = (options: TPauseEventHandlerOptions) => TCallback;

// This is a utility for keeping track of if the
// DRM session is likely to still be alive on the
// backend. If the user is paused for longer than
// 30 minutes it reports USER_INACTIVITY, which will
// kill the client playback session.
export const createPauseTimerUtility = (
  options: TPauseEventHandlerOptions
): TTimerUtility => {
  let listenersAdded = false;

  const initialHandlers: TInitialHandlers = {
    onPause: undefined,
    onPlaying: undefined,
  };

  let handlers: THandlers = {
    ...initialHandlers,
  };

  // 30 minute timeout, session will become invalid after a while and return 403
  const pauseTimeout: number = 30 * 60 * 1000;
  let pauseIntervalCheck: number;

  const getOnPause: TGetHandler = ({ event }) => {
    if (handlers.onPause) return handlers.onPause;

    handlers.onPause = (): void => {
      const pausedSince = Date.now();
      pauseIntervalCheck = window.setInterval(() => {
        if (pausedSince && Date.now() - pausedSince > pauseTimeout) {
          clearInterval(pauseIntervalCheck);

          event.publish({
            payload: {},
            type: WebPlayerEventType.USER_INACTIVITY,
          });
        }
      }, 100);
    };

    return handlers.onPause;
  };

  const getOnPlaying: TGetHandler = () => {
    if (handlers.onPlaying) return handlers.onPlaying;

    handlers.onPlaying = (): void => {
      if (pauseIntervalCheck) clearInterval(pauseIntervalCheck);
    };

    return handlers.onPlaying;
  };

  const pauseListenerArray: TPauseEventHandlerArray = [
    [PlaybackEventTypes.PAUSED, getOnPause],
    [PlaybackEventTypes.PLAYING, getOnPlaying],
  ];

  const addPauseTimeoutEventListeners = (): void => {
    if (listenersAdded) throw new Error("Pause event listeners already bound");

    pauseListenerArray.forEach(([event, getHandler]) =>
      options.event.on(event, getHandler(options))
    );

    listenersAdded = true;
  };

  const removePauseTimeoutEventListeners = (): void => {
    if (pauseIntervalCheck) clearInterval(pauseIntervalCheck);

    pauseListenerArray.forEach(([event, getHandler]) =>
      options.event.off(event, getHandler(options))
    );

    handlers = {
      ...initialHandlers,
    };

    listenersAdded = false;
  };

  return {
    start: addPauseTimeoutEventListeners,
    stop: removePauseTimeoutEventListeners,
  };
};
