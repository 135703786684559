import { getHHMMFromEpoch, getZeroBasedTimeString } from "../../utils";

export type TGetDotPositionOptions = {
  dragPosition: null | number;
  progressPercent: number;
  seekWindowPercent: null | number;
};

export const getProgressPosition = ({
  dragPosition,
  progressPercent,
  seekWindowPercent,
}: TGetDotPositionOptions): number => {
  if (dragPosition !== null) {
    return seekWindowPercent
      ? dragPosition * seekWindowPercent * 100
      : dragPosition * 100;
  }

  // TODO made sure progressPercent already always is a part of seekWindowPercent?
  return seekWindowPercent
    ? progressPercent * seekWindowPercent * 100
    : progressPercent * 100;
};

type TgetProgressDotLabel = {
  atLiveEdge?: boolean;
  currentTime?: number;
  endTime?: number;
  liveSeekEnabled?: boolean;
  seekLabelTimestamp: null | string;
  startTime?: number;
  translations: {
    a11yElapsed: string;
    a11yEndTime: string;
    a11yRemaining: string;
    a11yStartTime: string;
    a11yXofX: string;
  };
  vodDuration: null | number;
};

export const getProgressDotLabel = ({
  atLiveEdge,
  currentTime,
  endTime,
  liveSeekEnabled,
  // seekLabelTimestamp,
  startTime,
  translations,
  vodDuration,
}: TgetProgressDotLabel) => {
  const {
    a11yElapsed,
    a11yEndTime,
    a11yRemaining,
    a11yStartTime /* a11yXofX */,
  } = translations;
  // Pause and scrub
  if (currentTime && startTime && endTime && liveSeekEnabled) {
    return `${atLiveEdge ? "LIVE" : ""} ${getHHMMFromEpoch(startTime + currentTime * 1000)} ${a11yStartTime} ${getHHMMFromEpoch(startTime)} ${a11yEndTime} ${getHHMMFromEpoch(endTime)}`;
    // return `${seekLabelTimestamp ? seekLabelTimestamp : getHHMMFromEpoch(startTime + currentTime * 1000)} ${a11yXofX} ${getHHMMFromEpoch(endTime)}, ${a11yStartTime} ${getHHMMFromEpoch(startTime)} ${atLiveEdge ? "LIVE" : ""}`;
  }

  // VOD
  if (currentTime && vodDuration && vodDuration !== Infinity) {
    return `${a11yElapsed} ${getZeroBasedTimeString(currentTime)} ${a11yRemaining} ${getZeroBasedTimeString(vodDuration)}`;
  }
  // return `${getZeroBasedTimeString(currentTime)} ${a11yXofX} ${getZeroBasedTimeString(vodDuration)}, ${getZeroBasedTimeString(vodDuration - currentTime)} ${a11yRemaining}`;
  // Live without scrubbing
  if (startTime && currentTime && endTime) {
    return `${getHHMMFromEpoch(currentTime * 1000)}`;
    // return `${getHHMMFromEpoch(startTime + currentTime * 1000)} ${a11yXofX} ${getHHMMFromEpoch(endTime)}`
  }
  return "progress bar";
};
