import styled from "@emotion/styled";
import { FC } from "react";

import { TRelatedContentButtonProps } from "../@types/types";
import { HotkeyTooltips } from "../hooks/global-hotkeys.hook";
import { useCustomStyles } from "./context-provider";
import { GridIcon } from "./elements";
import { TextOverflowEllipsis } from "./overlays/elements";

const RelatedContentIcon = styled(GridIcon)<{ borderRadius: string }>`
  grid-column: 1;
  height: 100%;
  padding-left: 0.5em;
  padding-right: 0.5em;
  border-bottom-left-radius: ${({ borderRadius }) => borderRadius || "0.5em"};
  border-top-left-radius: ${({ borderRadius }) => borderRadius || "0.5em"};
`;

const RelatedContentText = styled.span<{ isAlone: boolean }>`
  grid-column: 2 / 4;
  overflow: hidden;
  height: 100%;
  font-size: 0.9em;
  ${({ isAlone }) => (isAlone ? `width: min-content;` : ``)}
`;

const TextBgWrapper = styled.div<{ borderRadius: string }>`
  height: 100%;
  border-bottom-right-radius: ${({ borderRadius }) => borderRadius || "0.5em"};
  border-top-right-radius: ${({ borderRadius }) => borderRadius || "0.5em"};

  display: flex;
  align-items: center;
  max-width: max-content;
`;

const TextInnerWrapper = styled(TextOverflowEllipsis)`
  padding: 0 1em 0 0;
`;

const RelatedContentButtonWrapper = styled.div<{ position: string }>`
  cursor: pointer;

  ${({ position }) => `
    grid-column: ${position};
    grid-row: 9;
  `}

  display: grid;

  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr;

  align-items: center;
  justify-content: center;

  --microtip-transition-delay: 0s;
  ::after {
    background: rgba(0, 0, 0, 0.8) !important;
    border-radius: 0.25em !important;
    padding: 0 0.75em !important;
    font-size: 0.9em !important;
    line-height: 1.8em !important;
    margin-bottom: 1.8em !important;
  }
  ::before {
    background: unset !important;
  }
  &:hover {
    --microtip-transition-delay: 1s;

    ${RelatedContentIcon}, ${TextBgWrapper} {
      background: rgba(255, 255, 255, 0.15);
    }
  }

  &:hover {
    svg {
      fill-opacity: 1;
      stroke-opacity: 1;
    }
  }
`;

export const RelatedContentButton: FC<TRelatedContentButtonProps> = ({
  clickHandler,
  Icon,
  isAlone,
  position,
  text,
}) => {
  const { borderRadius } = useCustomStyles();
  return (
    <RelatedContentButtonWrapper
      aria-label={`${text} (${HotkeyTooltips.RELATED.toUpperCase()})`}
      data-microtip-position="top"
      data-tooltip
      onClick={clickHandler}
      onDoubleClick={(e) => e.stopPropagation()}
      onKeyDown={(e) => {
        if (e.key === " " || e.key === "Enter") {
          clickHandler();
          e.stopPropagation();
          e.preventDefault();
        }
      }}
      onMouseLeave={(e) => e.currentTarget.blur()}
      position={position}
      role="button"
      tabIndex={0}
    >
      <RelatedContentIcon borderRadius={borderRadius} tabIndex={-1}>
        <Icon />
      </RelatedContentIcon>
      <RelatedContentText isAlone={isAlone}>
        <TextBgWrapper borderRadius={borderRadius}>
          <TextInnerWrapper>{text}</TextInnerWrapper>
        </TextBgWrapper>
      </RelatedContentText>
    </RelatedContentButtonWrapper>
  );
};
